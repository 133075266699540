import { toLocalISOString, getMinutesAgo, getLast30Days } from '../../functions/DateUtils';
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';

// Redux
// ----------------------------------------------------------------------

let initialState = {
    amznProducts: null,
    inHouseProducts: null,

    isAMZNProductsLoading: true,
    isInHouseProductsLoading: true,
    error: null,
};

export const slice = createSlice({
    name: "inventory",
    initialState,
    reducers: {
        // Setting Loading States
        setAMZNProductsLoading(state, action) {
            state.isAMZNProductsLoading = action.payload;
        },

        setInHouseProductsLoading(state, action) {
            state.isInHouseProductsLoading = action.payload;
        },

        // Setters for data
        setAmznProductsData(state, action) {
            state.amznProducts = action.payload;
        },

        setInHouseProductsData(state, action) {
            state.inHouseProducts = action.payload;
        },

        // Handle errors
        hasError(state, action) {
            state.error = action.payload;
        },
    },

});

export const {
    hasError,
} = slice.actions

export default slice.reducer;

export function fetchAmznProductsData(startDate, endDate) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(slice.actions.setAMZNProductsLoading(true));

    return async () => {
        try {
            const response = await axios.get(`${API_URL}/inventory/amazon/analysis?start_date=${startDate}&end_date=${endDate}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(slice.actions.hasError(null));
            dispatch(slice.actions.setAmznProductsData(response.data.data));
            dispatch(slice.actions.setAMZNProductsLoading(false));

        } catch (error) {
            console.error('Error fetching amazon inventory data:', error);
            dispatch(slice.actions.setAMZNProductsLoading(false));
            dispatch(slice.actions.hasError(error));
            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function fetchInHouseProductsData() {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(slice.actions.setInHouseProductsLoading(true));

    return async () => {
        try {
            const response = await axios.get(`${API_URL}/inventory/in-house/analysis`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(slice.actions.hasError(null));
            dispatch(slice.actions.setInHouseProductsData(response.data.data));
            dispatch(slice.actions.setInHouseProductsLoading(false));

        } catch (error) {
            console.error('Error fetching in-house inventory data:', error);
            dispatch(slice.actions.setInHouseProductsLoading(false));
            dispatch(slice.actions.hasError(error));
            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function updateInHouseProduct(data) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    return async () => {
        try {
            // Making a PATCH request to the backend to update the inventory item
            const response = await axios.patch(`${API_URL}/inventory/in-house/update`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            dispatch(fetchInHouseProductsData());

            console.log('Inventory item updated successfully:', response);
        } catch (error) {
            console.error('Error updating in-house inventory item:', error);
            dispatch(slice.actions.hasError(error));
            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function updateAmznProduct(data) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    return async () => {
        try {
            // Making a PATCH request to the backend to update the amzn inventory item data
            const response = await axios.patch(`${API_URL}/inventory/amazon/update`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const start_date = toLocalISOString(getLast30Days());
            const end_date = toLocalISOString(getMinutesAgo(3));

            dispatch(fetchAmznProductsData(start_date, end_date));

            console.log('Amazon inventory item updated successfully:', response);
        } catch (error) {
            console.error('Error updating amazon inventory item:', error);
            dispatch(slice.actions.hasError(error));
            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function addInHouseProduct(data) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    return async () => {
        try {
            // Making a POST request to the backend to add a new inventory item
            const response = await axios.post(`${API_URL}/inventory/in-house/add`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            dispatch(fetchInHouseProductsData());

            console.log('Inventory item added successfully:', response);
        } catch (error) {
            console.error('Error adding in-house inventory item:', error);
            dispatch(slice.actions.hasError(error));
            return Promise.reject(error); // Reject the promise on error
        }
    };
}
