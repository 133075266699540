import { useContext } from 'react';
import { ThemeContext, backgroundColor, textColor } from '../context/themeContext';

const useTooltipStyles = () => {
  const { theme } = useContext(ThemeContext);

  const tooltipStyles = {
    backgroundColor: theme === 'dark' ? backgroundColor : '#fff',
    borderColor: theme === 'dark' ? '#777' : '#ccc',
    color: theme === 'dark' ? textColor : '#000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px', // Adjust max width as needed
  };

  return tooltipStyles;
};

export default useTooltipStyles;
