import React, { useState } from 'react';
import {
    Box, Button, Dialog, DialogContent, DialogActions, TextField, DialogTitle, FormControlLabel, Switch,
} from '@mui/material';
import { useScrollbarStyles } from '../../globalStyles/ScrollBar';

const ProductProfileDialog = ({ product, productType, open, onClose, onSave, isAddingNew }) => {
    const scrollbarStyles = useScrollbarStyles();

    // Define a template for an empty product
    const emptyProductTemplate = {
        ProductID: '',
        ProductName: '',
        InventoryCount: '',
        CostOfProduct: '',
        StockValue: '',
        CostOfGoods: '',
        Barcode: '',
    };

    // Determine if the current product type is Amazon to set field properties accordingly
    const isAmazonProduct = productType === 'amazon';

    // Initialise the product form with empty fields if adding a new product, otherwise use existing product data
    const [editedProduct, setEditedProduct] = useState(isAddingNew ? emptyProductTemplate : product);

    // Formulate the dialog title dynamically based on the action (add/edit) and the product type
    const dialogTitle = isAddingNew
        ? 'Add New Product'
        : isAmazonProduct
            ? 'Amazon Product Profile'
            : 'In-House Product Profile';

    // Update the state when form fields change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedProduct({ ...editedProduct, [name]: value });
    };

    const handleSwitchChange = (e) => {
        setEditedProduct({ ...editedProduct, NotificationsEnabled: e.target.checked });
    };

    // Handle saving the product, whether new or updated
    const handleSave = () => {
        onSave(editedProduct);
        onClose(); // Close the dialog upon save
    };

    // If no product data is available, do not render the dialog
    if (!product && !isAddingNew) {
        return null;
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={{ ...scrollbarStyles }}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ display: 'grid', gap: 2, marginTop: '10px' }}>
                    {isAmazonProduct && (
                        <FormControlLabel
                            control={<Switch checked={editedProduct.NotificationsEnabled || false} onChange={handleSwitchChange} name="NotificationsEnabled" />}
                            label="Notifications Enabled"
                        />
                    )}
                    {!isAddingNew && (
                        <TextField
                            label={productType === 'amazon' ? "ASIN" : "Product ID"}
                            value={productType === 'amazon' ? editedProduct.ASIN : editedProduct.ProductID}
                            name={productType === 'amazon' ? "ASIN" : "ProductID"}
                            onChange={handleChange}
                            disabled={true}
                        />
                    )}
                    <TextField
                        label="Product Name"
                        value={editedProduct.ProductName}
                        name="ProductName"
                        onChange={handleChange}
                        disabled={isAmazonProduct}
                    />
                    <TextField
                        label="Inventory Count"
                        type="number"
                        value={productType === 'amazon' ? editedProduct.TotalQuantity : editedProduct.InventoryCount}
                        name="InventoryCount"
                        onChange={handleChange}
                        disabled={isAmazonProduct}
                    />
                    <TextField
                        label="Cost of Goods"
                        type="number"
                        value={editedProduct.CostOfGoods}
                        name="CostOfGoods"
                        onChange={handleChange}
                    />
                    <TextField
                        label="Cost Of Product"
                        type="number"
                        value={productType === 'amazon' ? editedProduct.AmazonPrice : editedProduct.CostOfProduct}
                        name="CostOfProduct"
                        onChange={handleChange}
                        disabled={isAmazonProduct}
                    />
                    <TextField
                        label="Stock Value"
                        type="number"
                        value={productType === 'amazon' ? editedProduct.StockValue : editedProduct.CostOfProduct * editedProduct.InventoryCount}
                        name="StockValue"
                        onChange={handleChange}
                        disabled={true}
                    />
                    <TextField
                        label="Barcode"
                        value={editedProduct.Barcode || ''}
                        name="Barcode"
                        onChange={handleChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary">{isAddingNew ? 'Add' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductProfileDialog;