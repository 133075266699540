import React, { useContext, useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import { Paper, Typography, Box, CircularProgress } from '@mui/material';

import { ThemeContext, primaryColor, contrastPrimaryColor, secondaryColor } from '../../context/themeContext';

const TotalNetProfit = ({ products, toolTipStyles, loading }) => {
    const { theme } = useContext(ThemeContext);

    // Check if products (NetProfits) is available
    const hasProducts = products;

    // Calculate total sales and profits
    const totalSales = hasProducts ? products.sales : 0;
    const totalProfits = hasProducts ? products.profit : 0;

    return (
        <Paper
            sx={{
                borderRadius: '15px',
                boxShadow: '0px 4px 4px 1px rgba(0, 0, 0, 0.25)',
                p: 2,
                mt: 5,
                maxWidth: '100vw',  // Limit the overall width of the paper
                overflow: 'hidden',   // Ensure content doesn't overflow
            }}
            elevation={3}
        >
            <Typography variant="h6" gutterBottom>
                Total Net Profit
            </Typography>
            {loading ? (
                // Show loading spinner if data is still being fetched
                <Box display="flex" justifyContent="center" alignItems="center" height={300}>
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            data={[products]}  // Data passed as the products prop
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip contentStyle={toolTipStyles} />  {/* Use the tooltip styles passed as a prop */}
                            <Legend />
                            <Bar dataKey="sales" fill={theme === 'dark' ? primaryColor : '#8884d8'}>
                                <LabelList dataKey="sales" position="top" />
                            </Bar>
                            <Bar dataKey="profit" fill={theme === 'dark' ? contrastPrimaryColor : '#82ca9d'}>
                                <LabelList dataKey="profit" position="top" />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 2,
                        }}
                    >
                        <Box
                            sx={{
                                p: 2,
                                textAlign: 'center',
                                backgroundColor: theme === 'dark' ? secondaryColor : '#eeeeee',
                                borderRadius: 1,
                                flex: 1,
                                mr: 1,
                            }}
                        >
                            <Typography variant="button" display="block" gutterBottom>
                                Sales
                            </Typography>
                            <Typography variant="h6" component="p">
                                {totalSales?.toLocaleString()}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                p: 2,
                                textAlign: 'center',
                                backgroundColor: theme === 'dark' ? secondaryColor : '#eeeeee',
                                borderRadius: 1,
                                flex: 1,
                                ml: 1,
                            }}
                        >
                            <Typography variant="button" display="block" gutterBottom>
                                Profits
                            </Typography>
                            <Typography variant="h6" component="p">
                                {totalProfits?.toLocaleString()}
                            </Typography>
                        </Box>
                    </Box>
                </React.Fragment>
            )}
        </Paper>
    );
};

export default TotalNetProfit;
