import React from 'react';
import { Box, Typography } from '@mui/material';
import UpTrend from '../assets/UpTrend.svg';
import DownTrend from '../assets/DownTrend.svg';

const TrendInfo = ({ trend }) => {
  if (!trend) {
    return <Box style={{ height: '20px', width: '100%' }} />; // Empty box if no trend
  }

  // Check if trend is a number
  const trendValue = parseFloat(trend);
  const isNumberTrend = !isNaN(trendValue) && isFinite(trendValue);

  // Determine trend direction and color
  const isNeutralTrend = isNumberTrend && trendValue === 0;
  const isDownTrend = isNumberTrend && trendValue < 0;
  const isUpTrend = isNumberTrend && trendValue > 0;

  const iconSrc = isUpTrend ? UpTrend : isDownTrend ? DownTrend : null;
  const altText = isUpTrend ? "Up Trend" : isDownTrend ? "Down Trend" : null;

  // Determine color based on trend type
  const trendColor = isNeutralTrend
    ? 'inherit'
    : isDownTrend
      ? '#F93C65'
      : isUpTrend
        ? '#00B69B'
        : 'textSecondary'; // Default color for non-numeric trends

  return (
    <Box display="flex" alignItems="center" sx={{ pt: 1 }}>
      {iconSrc ? (
        <img
          src={iconSrc}
          alt={altText}
          style={{ width: '20px', height: '20px', marginRight: '5px' }}
        />
      ) : (
        <Box
          style={{
            width: '0px',
            height: '20px',
          }}
        />
      )}
      <Typography
        component="span"
        color={trendColor}
        sx={{
          fontFamily: 'Nunito Sans',
          fontSize: '13px',
          fontWeight: 600,
        }}
      >
        {isNeutralTrend ? '' : trend}
      </Typography>
    </Box>
  );
};

export default TrendInfo;
