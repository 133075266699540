import { useTheme } from '@mui/material/styles';

export const useScrollbarStyles = () => {
    const theme = useTheme(); // Access the theme

    // Styles for the scrollbar
    const scrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '4px'
        },
        // For Firefox
        scrollbarColor: `${theme.palette.primary.main} #e0e0e0`,
        scrollbarWidth: 'thin',
    };

    return scrollbarStyles;
};
