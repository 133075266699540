import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from '../../context/themeContext';
import { Box, Typography, Grid } from '@mui/material';
import SummaryCard from '../../components/SummaryCard';
import OrdersToday from '../../components/Graphs/OrdersToday';
import OrdersAnalysis from '../../components/Graphs/OrdersAnalysis';
// import TotalNetProfit from '../../components/Graphs/TotalNetProfit';
import TimeFrameSelector from '../../components/Layout/TimeFrameSelector';
import OrdersTable from '../../components/Tables/OrdersTable';
import RepeatCustomersTable from '../../components/Tables/RepeatCustomersTable';
import { useNavigate } from 'react-router-dom';

// SVGs
import GraphIcon from '../../assets/GraphIcon.svg';
import InventoryIcon from '../../assets/InventoryIcon.svg';
import PendingIcon from '../../assets/PendingIcon.svg';

// Redux
import { useDispatch, useSelector } from '../../redux/store';
import {
  fetchAllOrdersData,
  fetchTimeDistributionData,
  fetchRepeatCustomersData,
  fetchMostSoldData,
  fetchTrendData,
  fetchPayout,
  fetchProfitData,
  setOrdersLoading,
  setExpectedPayoutToday,
  setRepeatCustomersLoading,
  setDistrubitionLoading,
  setMostSoldLoading,
  setProfitDataLoading,
} from "../../redux/slices/analytics";

// General Functions
import {
  toLocalISOString,
  getEndOfYesterday,
  getMinutesAgo
} from '../../functions/DateUtils';

// Styles
import useTooltipStyles from '../../globalStyles/ToolTip';
import TotalNetProfit from '../../components/Graphs/TotalNetProfit';

const AnalyticsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const tooltipStyles = useTooltipStyles();

  const {
    orderDistributions,
    allOrders,
    repeatCustomers,
    ordersAnalysis,
    trendData,
    expectedPayoutToday,
    expectedPayoutYesterday,
    profitData,
    isOrdersLoading,
    isDistributionsLoading,
    isRepeatCustomersLoading,
    isMostSoldLoading,
    isTrendDataLoading,
    isExpectedPayoutTodayLoading,
    isExpectedPayoutYesterdayLoading,
    isProfitDataLoading,
  } = useSelector((state) => state.analytics);

  const [timeView, setTimeView] = useState('today');
  const [selectedRange, setSelectedRange] = useState({
    startDate: getEndOfYesterday(),
    endDate: getMinutesAgo(3),
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setOrdersLoading(true));
      dispatch(setRepeatCustomersLoading(true));
      dispatch(setDistrubitionLoading(true));
      dispatch(setMostSoldLoading(true));
      dispatch(setProfitDataLoading(true));

      try {
        let payoutToday
        let payoutYesterday

        const formattedStartDate = toLocalISOString(selectedRange.startDate);
        const formattedEndDate = toLocalISOString(selectedRange.endDate);

        await dispatch(fetchTrendData(formattedStartDate, formattedEndDate, navigate));
        await dispatch(fetchAllOrdersData(formattedStartDate, formattedEndDate, navigate));
        await dispatch(fetchTimeDistributionData(formattedStartDate, formattedEndDate, navigate));
        await dispatch(fetchRepeatCustomersData(formattedStartDate, formattedEndDate, navigate));
        await dispatch(fetchMostSoldData(formattedStartDate, formattedEndDate, navigate));

        payoutToday = await dispatch(fetchPayout(formattedStartDate, formattedEndDate, navigate));
        await dispatch(setExpectedPayoutToday(payoutToday));
        await dispatch(fetchProfitData(formattedStartDate, formattedEndDate, payoutToday, navigate));

      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    if (selectedRange.startDate && selectedRange.endDate) {
      fetchData();
    }
  }, [dispatch, selectedRange.startDate, selectedRange.endDate]);

  const handleDateChange = (startDate, endDate) => {
    setSelectedRange({ startDate, endDate });
    console.log(startDate, endDate);
  };

  const summaryData = [
    { title: `Sum of Sales (${timeView})`, value: `£${trendData?.last_period?.total_sales?.toFixed(2)}`, icon: GraphIcon, trend: `${trendData?.sales_change_percentage?.toFixed(2)}% from previous period`, loading: isTrendDataLoading },
    { title: `Units Sold (${timeView})`, value: `${trendData?.last_period?.total_units_sold}`, icon: InventoryIcon, trend: `${trendData?.units_sold_change_percentage?.toFixed(2)}% from previous period`, loading: isTrendDataLoading },
    { title: `Expected Payout (${timeView})`, value: `£${expectedPayoutToday?.toFixed(2)}`, trend: `Based on today's fee rates`, icon: PendingIcon, loading: isExpectedPayoutTodayLoading && isExpectedPayoutYesterdayLoading },
  ];

  return (
    <Box
      sx={{
        color: theme === 'dark' ? 'common.white' : 'common.black',
        bgcolor: theme === 'dark' ? 'background.default' : 'background.paper',
        p: 3,
        minHeight: 'calc(100vh)',
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Amazon Analytics
      </Typography>

      <TimeFrameSelector onDateChange={handleDateChange} onViewChange={(e) => { setTimeView(e) }} timeView={timeView} />

      <Grid container spacing={2} sx={{ mb: 4 }}>
        {summaryData.map((data, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <SummaryCard {...data} />
          </Grid>
        ))}
      </Grid>

      <OrdersTable data={allOrders} loading={isOrdersLoading} />

      <OrdersToday orders={orderDistributions} toolTipStyles={tooltipStyles} loading={isDistributionsLoading} />

      <RepeatCustomersTable data={repeatCustomers} loading={isRepeatCustomersLoading} />

      <OrdersAnalysis products={ordersAnalysis} toolTipStyles={tooltipStyles} loading={isMostSoldLoading} />
     
      <TotalNetProfit products={profitData} toolTipStyles={tooltipStyles} loading={isProfitDataLoading}  />

    </Box>
  );
};

export default AnalyticsPage;
