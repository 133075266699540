import React, { useEffect} from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Sidebar from './components/Layout/Sidebar';

import DashboardPage from './pages/Dashboard/Dashboard';
import Inventory from './pages/Inventory/Inventory';
import AmazonAnalytics from './pages/Analytics/Analytics';
// import Settings from './pages/Settings';
import Ledger from './pages/Ledger/Ledger';

import { useDispatch, useSelector } from './redux/store';
import { fetchOosProducts } from '../src/redux/slices/analytics'; // Assume you have an API function to fetch out-of-stock products

const App = ({ onLogout }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    oosProducts = [],
    isOosLoading,
    error,
  } = useSelector((state) => state.analytics);

  useEffect(() => {
    // Fetch out-of-stock products when the component mounts
    dispatch(fetchOosProducts(navigate));
  }, []);

  return (
    <Box display="flex">
      <Sidebar onLogout={onLogout} outOfStockProducts={oosProducts} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - 240px)` }, // Assuming the sidebar width is 240px
          paddingTop: isMobile ? '56px' : '0px', // Apply padding only if mobile
          transition: theme.transitions.create('padding-top', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Routes>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/analytics" element={<AmazonAnalytics />} />
          {/* <Route path="/settings" element={<Settings />} /> */}
          <Route path="/ledger" element={<Ledger />} />
          {/* <Route path="*" element={<HomePage />} /> */}
        </Routes>
      </Box>
    </Box>
  );
};

export default App;
