import { combineReducers } from 'redux';
import analyticsReducer from './slices/analytics';
import inventoryReducer from './slices/inventory';
import ledgerReducer from './slices/ledger';

const rootReducer = combineReducers({
  analytics: analyticsReducer,
  inventory: inventoryReducer,
  ledger: ledgerReducer,
});

export { rootReducer };