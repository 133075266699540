import React, { useState, useEffect, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';
import { ThemeProvider } from './context/themeContext';
import { SnackbarProvider } from 'notistack';

import { useLocation } from 'react-router-dom';

import App from './App';
import './index.css';
import SignIn from './pages/Login/SignIn';
import ErrorPage from './pages/Error/ErrorPage';
import reportWebVitals from './reportWebVitals';

// This function checks the stored token's validity
const checkAuthStatus = () => {
  const expiryTime = localStorage.getItem('expiry_time');
  return expiryTime && new Date().getTime() < expiryTime;
};

const HandleRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthStatus());
  const [justLoggedIn, setJustLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignIn = (response) => {
    const { access_token, expires_in, name, email } = response.data;
    const expiryTime = new Date().getTime() + expires_in * 1000;

    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    localStorage.setItem('access_token', access_token);  // Correct token key
    localStorage.setItem('expiry_time', expiryTime.toString());  // Token expiry

    setIsAuthenticated(true);
    setJustLoggedIn(true);
  };

  const handleLoginError = (error) => {
    console.error('Login failed! Error:', error);
  };

  const logoutUser = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    if (!accessToken) {
      console.error('Logout failed! Error: No access token found, unable to log out.');
      return;
    }

    fetch(`${API_URL}/users/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ access_token: accessToken }),
    })
      .then(async response => {
        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.message || 'Failed to log out');
        }

        localStorage.removeItem('expiry_time');
        localStorage.removeItem('access_token');
        localStorage.removeItem('name');
        localStorage.removeItem('email');

        setIsAuthenticated(false);
        navigate('/signin');
      })
      .catch(error => {
        console.error('Logout failed! Error:', error.message);
        navigate('/error', { state: { errorMessage: error.message } });
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!checkAuthStatus()) {
        console.log("Token has expired. Redirecting to sign-in page.");
        logoutUser();
      }
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [navigate]);

  useLayoutEffect(() => {
    if (justLoggedIn) {
      navigate(process.env.REACT_APP_REDIRECT_URI);
      setJustLoggedIn(false);
    }
  }, [justLoggedIn, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/dashboard" />} />
      <Route path="/*" element={isAuthenticated ? <App onLogout={logoutUser} /> : <Navigate replace to="/signin" />} />
      <Route path="/signin" element={<SignIn onSignIn={handleSignIn} errorSignIn={handleLoginError} />} />
      <Route path="/error" element={<ErrorPage errorMessage={location.state?.errorMessage} />} />
    </Routes>
  );
};

const Index = () => {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <HandleRoutes />
      </BrowserRouter>
    </ReduxProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>

  <ThemeProvider>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Index />
    </SnackbarProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
