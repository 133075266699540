import React, { useState, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Typography,
    CircularProgress,
    Box,
    TextField,
    TableSortLabel,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useScrollbarStyles } from '../../globalStyles/ScrollBar';

const OutOfStockProducts = ({ products = [], loading }) => {
    const scrollbarStyles = useScrollbarStyles();
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('ASIN');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const filteredProducts = useMemo(() => {
        if (!Array.isArray(products)) return [];
        return products.filter(product =>
            product.ProductName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.ASIN.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [products, searchQuery]);

    const sortedProducts = useMemo(() => {
        return filteredProducts.slice().sort((a, b) => {
            if (orderBy === 'ASIN' || orderBy === 'ProductName') {
                return (a[orderBy].localeCompare(b[orderBy])) * (orderDirection === 'asc' ? 1 : -1);
            }
            return 0;
        });
    }, [filteredProducts, orderBy, orderDirection]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedProducts.length) : 0;

    return (
        <Paper
            sx={{
                borderRadius: '15px',
                boxShadow: '0px 4px 4px 1px rgba(0, 0, 0, 0.25)',
                p: 2,
                mt: 5,
                maxWidth: '100vw',  // Limit the overall width of the paper
                overflow: 'hidden',   // Ensure content doesn't overflow
            }}
            elevation={3}
        >
            <Typography variant="h6" gutterBottom>
                Out of Stock Products
            </Typography>
            <Box mb={2}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{ width: '100%' }}
                    disabled={loading}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : products.length === 0 ? (
                <Typography variant="body1" align="center">
                    All products are in stock
                </Typography>
            ) : filteredProducts.length === 0 ? (
                <Typography variant="body1" align="center">
                    No products match your search
                </Typography>
            ) : (
                <>
                    <TableContainer sx={{ maxHeight: 440, boxShadow: 'none', border: 'none', ...scrollbarStyles }}>
                        <Table stickyHeader aria-label="out of stock products table" sx={{ border: 'none' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sortDirection={orderBy === 'ASIN' ? orderDirection : false}>
                                        <TableSortLabel
                                            active={orderBy === 'ASIN'}
                                            direction={orderBy === 'ASIN' ? orderDirection : 'asc'}
                                            onClick={() => handleRequestSort('ASIN')}
                                        >
                                            ASIN
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={orderBy === 'ProductName' ? orderDirection : false}>
                                        <TableSortLabel
                                            active={orderBy === 'ProductName'}
                                            direction={orderBy === 'ProductName' ? orderDirection : 'asc'}
                                            onClick={() => handleRequestSort('ProductName')}
                                        >
                                            Product Name
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell>{product.ASIN}</TableCell>
                                        <TableCell>{product.ProductName}</TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={sortedProducts.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
    );
};

export default OutOfStockProducts;
