import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useTooltipStyles from '../globalStyles/ToolTip';

const CustomTooltip = ({ active, payload, label }) => {
    const tooltipStyles = useTooltipStyles();
    const theme = useTheme();
    
    if (active && payload && payload.length) {
        return (
            <Box
                sx={{
                    backgroundColor: tooltipStyles.backgroundColor,
                    border: `1px solid ${tooltipStyles.borderColor}`,
                    color: tooltipStyles.color,
                    p: 1,
                    maxWidth: '300px', // Ensure max width to prevent overflow
                    whiteSpace: 'normal', // Allow text to wrap
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '12px', // Smaller font size
                }}
            >
                <Typography variant="body2" component="p" style={{ color: tooltipStyles.color, fontSize: '12px' }}>
                    {label}
                </Typography>
                <Typography variant="body2" component="p" style={{ color: theme.palette.primary.main, fontSize: '12px' }}>
                    {`${payload[0].name}: ${payload[0].value}`}
                </Typography>
            </Box>
        );
    }

    return null;
};

export default CustomTooltip;
