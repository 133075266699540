import React, { useState, useEffect } from 'react';
import { Paper, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import CalendarPicker from '../Calendar/CalendarPicker';
import {
    getStartOfYesterday,
    getEndOfYesterday,
    getMinutesAgo,
    getLast7Days,
    getLast30Days,
    getLast12Months
} from '../../functions/DateUtils';

// Add the timeView prop to the function parameters
const TimeFrameSelector = ({ onDateChange, onViewChange, timeView }) => {
    const theme = useTheme(); // Access theme
    const [showPicker, setShowPicker] = useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: getEndOfYesterday(),
            endDate: getMinutesAgo(3),
            key: 'selection'
        }
    ]);

    const updateTimeFrame = (timeFrame) => {
        let startDate, endDate;

        switch (timeFrame) {
            case 'today':
                startDate = getEndOfYesterday();
                endDate = getMinutesAgo(3);
                break;
            case '7days':
                startDate = getLast7Days();
                endDate = getMinutesAgo(3);
                break;
            case '30days':
                startDate = getLast30Days();
                endDate = getMinutesAgo(3);
                break;
            case '12months':
                startDate = getLast12Months();
                endDate = getMinutesAgo(3);
                break;
            default:
                startDate = getStartOfYesterday();
                endDate = getMinutesAgo(3);
        }

        onViewChange(timeFrame);
        onDateChange(startDate, endDate); // Pass the start and end dates to the parent component
    };

    const handleTimeFrameChange = (event, newTimeFrame) => {
        if (newTimeFrame !== null) {
            // Directly call onViewChange instead of setTimeFrame
            onViewChange(newTimeFrame);
            if (newTimeFrame === 'other') {
                // If "Other" is selected, show the date picker
                setShowPicker(true);
            } else {
                // If any other time frame is selected, update the dates and hide picker
                updateTimeFrame(newTimeFrame);
                setShowPicker(false);
            }
        }
    };

    useEffect(() => {
        if (dateRange[0]?.endDate && timeView === 'other') {
            const now = new Date();
            let startDate = new Date(dateRange[0].startDate);
            let endDate = new Date(dateRange[0].endDate);

            // If the start date and end date are the same, adjust the start date
            if (startDate.toDateString() === endDate.toDateString()) {
                startDate = new Date(startDate);
                startDate.setDate(startDate.getDate() - 1);
                startDate.setHours(23, 59, 59, 999);
            }

            // Adjust the end date based on whether it is today or not
            if (endDate.toDateString() === now.toDateString()) {
                endDate = getMinutesAgo(3);
            } else {
                endDate.setHours(23, 59, 59, 999);
            }

            onDateChange(startDate, endDate);
        }
    }, [dateRange[0]?.endDate, timeView]);

    return (
        <>
            <Paper sx={{ mb: 3 }}>
                <ToggleButtonGroup
                    size="small"
                    value={timeView} // Use the timeView prop for setting the initial value
                    exclusive
                    onChange={handleTimeFrameChange}
                    aria-label="time frame"
                    sx={{
                        '& .MuiToggleButtonGroup-grouped': {
                            margin: '8px',
                            border: 0,
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.mode === 'dark' ? '#00' : '#555',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: theme.palette.mode === 'dark' ? '#00' : '#333',
                                },
                            },
                            '&:not(:first-of-type)': {
                                borderRadius: '4px',
                            },
                            '&:first-of-type': {
                                borderRadius: '4px',
                            },
                        },
                    }}
                >
                    <ToggleButton value="today" aria-label="today">Today</ToggleButton>
                    <ToggleButton value="7days" aria-label="last 7 days">Last 7 days</ToggleButton>
                    <ToggleButton value="30days" aria-label="last 30 days">Last 30 days</ToggleButton>
                    <ToggleButton value="12months" aria-label="last 12 months">Last 12 months</ToggleButton>
                    <ToggleButton value="other" aria-label="other">Other</ToggleButton>
                </ToggleButtonGroup>
            </Paper>
            <CalendarPicker
                open={showPicker}
                onClose={() => setShowPicker(false)}
                dateRange={dateRange}
                setDateRange={setDateRange}
            />
        </>
    );
};

export default TimeFrameSelector;
