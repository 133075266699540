import React, { useContext } from 'react';
import { ThemeContext, primaryColor } from '../../context/themeContext';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// Styles
import useTooltipStyles from '../../globalStyles/ToolTip';

const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};

const LineGraph = ({ data }) => {
    const { theme } = useContext(ThemeContext);
    const tooltipStyles = useTooltipStyles();

    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tickFormatter={formatXAxis} />
                <YAxis />
                <Tooltip contentStyle={tooltipStyles} />
                <Legend />
                <Line type="monotone" dataKey="totalValue" stroke={theme === 'dark' ? primaryColor : '#8884d8'} activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default LineGraph;
