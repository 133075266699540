import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../context/themeContext';
import { Box, Typography, Container, Card, CardContent, TextField, Button, Alert } from '@mui/material';

const SignIn = ({ onSignIn, errorSignIn }) => {
  const { theme } = useContext(ThemeContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      // Call the authenticate backend API endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || 'Invalid email or password');
        errorSignIn(data.message);
        return;
      }

      // Save token and notify parent component of successful login
      localStorage.setItem('access_token', data.data.access_token);  // Access Token
      localStorage.setItem('expiry_time', Date.now() + data.data.expires_in * 1000);  // Token expiration
      onSignIn(data);
    } catch (error) {
      setError('An error occurred. Please try again.');
      errorSignIn(error.message);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="100%"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        bgcolor: theme === 'dark' ? 'background.default' : 'background.paper',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          mt: 2,
        }}
      >
        <Card raised sx={{ p: 3, maxWidth: '400px' }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant="h5" component="h1" gutterBottom>
              Sign Into Your Account
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Authorised access ONLY
            </Typography>
            
            {error && <Alert severity="error">{error}</Alert>}
            
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              sx={{ mt: 2 }}
              fullWidth
            >
              Sign In
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default SignIn;