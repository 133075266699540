import React, { useEffect, useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  TableSortLabel,
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getComparator } from '../../functions/GeneralFunctions';
import { useScrollbarStyles } from '../../globalStyles/ScrollBar';

const OrdersTable = ({ data, loading }) => {
  const scrollbarStyles = useScrollbarStyles();
  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  // Ensure data is always an array
  const safeData = data || [];

  // Handle search
  const filteredData = useMemo(() => {
    return safeData.filter(row => {
      return (
        row.orderNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.postCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.units.toString().includes(searchQuery.toLowerCase()) ||
        row.salesFigure.toString().includes(searchQuery.toLowerCase())
      );
    });
  }, [safeData, searchQuery]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  // Create a deep copy of the data to sort, to avoid mutating original data
  const sortedData = filteredData
    .map(item => ({ ...item })) // Create a shallow copy of each item if necessary
    .sort(getComparator(orderDirection, orderBy));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // If the number of maximum rows changes, update the current page to be 0
  useEffect(() => {
    setPage(0);
  }, [filteredData.length]);

  return (
    <Paper
      sx={{
        borderRadius: '15px',
        boxShadow: '0px 4px 4px 1px rgba(0, 0, 0, 0.25)',
        p: 2, // padding inside the card
        mt: 5, // margin-top for spacing from other elements
        maxWidth: '100vw',  // Limit the overall width of the paper
        overflow: 'hidden',   // Ensure content doesn't overflow
      }}
    >
      <Typography variant="h6" gutterBottom>
        List Of All Orders
      </Typography>
      <Box mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          disabled={loading}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          <TableContainer sx={{ maxHeight: 440, boxShadow: 'none', border: 'none', ...scrollbarStyles }}>
            <Table stickyHeader aria-label="sticky table" sx={{ border: 'none' }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'orderNumber'}
                      direction={orderBy === 'orderNumber' ? orderDirection : 'asc'}
                      onClick={() => handleRequestSort('orderNumber')}
                    >
                      Order Number
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'date'}
                      direction={orderBy === 'date' ? orderDirection : 'asc'}
                      onClick={() => handleRequestSort('date')}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'productName'}
                      direction={orderBy === 'productName' ? orderDirection : 'asc'}
                      onClick={() => handleRequestSort('productName')}
                    >
                      Product Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'postCode'}
                      direction={orderBy === 'postCode' ? orderDirection : 'asc'}
                      onClick={() => handleRequestSort('postCode')}
                    >
                      Post Code
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'units'}
                      direction={orderBy === 'units' ? orderDirection : 'asc'}
                      onClick={() => handleRequestSort('units')}
                    >
                      Units
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'salesFigure'}
                      direction={orderBy === 'salesFigure' ? orderDirection : 'asc'}
                      onClick={() => handleRequestSort('salesFigure')}
                    >
                      Sales Figure
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{row.orderNumber}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.productName}</TableCell>
                    <TableCell>{row.postCode}</TableCell>
                    <TableCell>{row.units}</TableCell>
                    <TableCell>£{row.salesFigure}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </React.Fragment>
      )}
    </Paper>
  );
};

export default OrdersTable;
