import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    Paper,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { ThemeContext } from '../../context/themeContext';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { fetchTransactions, addTransactionToDb, deleteTransactionFromDb } from '../../redux/slices/ledger';
import TransactionForm from '../../components/Forms/TransactionForm';
import LineGraph from '../../components/Graphs/LedgerLineGraph';
import LedgerTable from '../../components/Tables/LedgerTable';
import { useNavigate } from 'react-router-dom';

const LedgerPage = () => {
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { transactions, isLoading, isAdding, isDeleting } = useSelector((state) => state.ledger);

    const [isDialogOpen, setDialogOpen] = useState(false);
    const [newEntry, setNewEntry] = useState({
        type: '',
        customerName: '',
        date: new Date().toISOString().split('T')[0], // Default to today's date
        amount: ''
    });
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
    const [filter, setFilter] = useState('all');
    const [filteredTransactions, setFilteredTransactions] = useState(transactions);

    useEffect(() => {
        // Fetch transactions data when component mounts
        dispatch(fetchTransactions())
            .catch(error => {
                console.error('Logout failed! Error:', error.message);
                navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message
            });
    }, [dispatch, enqueueSnackbar]);

    useEffect(() => {
        applyFilter();
    }, [filter, transactions]);

    const applyFilter = () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        let filteredData = transactions;

        if (filter === 'thisMonth') {
            filteredData = transactions.filter(entry => {
                const entryDate = new Date(entry.TransactionDate);
                return entryDate.getMonth() === currentMonth && entryDate.getFullYear() === currentYear;
            });
        } else if (filter === 'thisYear') {
            filteredData = transactions.filter(entry => {
                const entryDate = new Date(entry.TransactionDate);
                return entryDate.getFullYear() === currentYear;
            });
        }

        setFilteredTransactions(filteredData);
    };

    const handleAddEntry = async (entry) => {
        try {
            await dispatch(addTransactionToDb({
                TransactionType: entry.type,
                CustomerName: entry.customerName,
                TransactionDate: entry.date,
                Amount: parseFloat(entry.amount)
            }));
            setDialogOpen(false);
            setNewEntry({ type: '', customerName: '', date: new Date().toISOString().split('T')[0], amount: '' });
            enqueueSnackbar('Transaction added successfully', { variant: 'success' });
        } catch (error) {
            console.error('Failed to add transaction:', error);
            setIsErrorPopupOpen(true);
            enqueueSnackbar('Failed to add transaction', { variant: 'error' });
        }
    };

    const handleDeleteEntry = async (entryId) => {
        try {
            await dispatch(deleteTransactionFromDb(entryId));
            enqueueSnackbar('Transaction deleted successfully', { variant: 'success' });
        } catch (error) {
            console.error('Failed to delete transaction:', error);
            setIsErrorPopupOpen(true);
            enqueueSnackbar('Failed to delete transaction', { variant: 'error' });
        }
    };

    // Process data for the line graph
    const lineGraphData = filteredTransactions.map((entry) => ({
        date: entry.TransactionDate,
        totalValue: entry.Amount
    }));

    // Calculate total amount
    const totalAmount = filteredTransactions.reduce((acc, entry) => acc + entry.Amount, 0);

    return (
        <Box
            sx={{
                color: theme === 'dark' ? 'common.white' : 'common.black',
                bgcolor: theme === 'dark' ? 'background.default' : 'background.paper',
                p: 3,
                minHeight: 'calc(100vh)',
                boxSizing: 'border-box',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">Ledger</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setDialogOpen(true)}
                >
                    Add Transaction
                </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <Select
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    >
                        <MenuItem value="all">All Time</MenuItem>
                        <MenuItem value="thisMonth">This Month</MenuItem>
                        <MenuItem value="thisYear">This Year</MenuItem>
                    </Select>
                </FormControl>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Total Amount: £{totalAmount.toFixed(2)}
                </Typography>
            </Box>
            <TransactionForm open={isDialogOpen} onAddEntry={handleAddEntry} handleClose={() => setDialogOpen(false)} transaction={newEntry} setTransaction={setNewEntry} />
            <Paper sx={{ p: 2, mb: 4 }}>
                <LineGraph data={lineGraphData} />
            </Paper>
            <LedgerTable ledgerEntries={filteredTransactions} handleDeleteEntry={handleDeleteEntry} />
            {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            )}
            {isAdding && <Typography sx={{ mt: 2 }}>Adding transaction...</Typography>}
            {isDeleting && <Typography sx={{ mt: 2 }}>Deleting transaction...</Typography>}

        </Box>
    );
};

export default LedgerPage;
