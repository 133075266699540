import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Typography,
    IconButton,
    TableSortLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useScrollbarStyles } from '../../globalStyles/ScrollBar';
import DeleteConfirmationDialog from '../../components/Modals/DeleteConfirmation';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const LedgerTable = ({ ledgerEntries, handleDeleteEntry }) => {
    const scrollbarStyles = useScrollbarStyles();
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickOpen = (entry) => {
        setSelectedEntry(entry);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedEntry(null);
    };

    const handleConfirmDelete = () => {
        if (selectedEntry) {
            handleDeleteEntry(selectedEntry.TransactionID);
        }
        handleClose();
    };

    const sortedEntries = ledgerEntries
        .map(item => ({ ...item })) // Create a shallow copy of each item if necessary
        .sort((a, b) => {
            if (orderBy === 'date') {
                const dateA = new Date(a.TransactionDate);
                const dateB = new Date(b.TransactionDate);
                return (dateA - dateB) * (orderDirection === 'asc' ? 1 : -1);
            } else if (orderBy === 'amount') {
                return (a.Amount - b.Amount) * (orderDirection === 'asc' ? 1 : -1);
            } else {
                return a[orderBy].localeCompare(b[orderBy]) * (orderDirection === 'asc' ? 1 : -1);
            }
        });

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedEntries.length) : 0;

    return (
        <Paper
            sx={{
                borderRadius: '15px',
                boxShadow: '0px 4px 4px 1px rgba(0, 0, 0, 0.25)',
                p: 2, // padding inside the card
                mt: 5, // margin-top for spacing from other elements
                maxWidth: '100vw',  // Limit the overall width of the paper
                overflow: 'hidden',   // Ensure content doesn't overflow
            }}
        >
            <Typography variant="h6" gutterBottom>
                Ledger Entries
            </Typography>
            <TableContainer sx={{ maxHeight: 440, boxShadow: 'none', border: 'none', ...scrollbarStyles }}>
                <Table stickyHeader aria-label="sticky table" sx={{ border: 'none' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={orderBy === 'date' ? orderDirection : false}>
                                <TableSortLabel
                                    active={orderBy === 'date'}
                                    direction={orderBy === 'date' ? orderDirection : 'asc'}
                                    onClick={() => handleRequestSort('date')}
                                >
                                    Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell align="right" sortDirection={orderBy === 'amount' ? orderDirection : false}>
                                <TableSortLabel
                                    active={orderBy === 'amount'}
                                    direction={orderBy === 'amount' ? orderDirection : 'asc'}
                                    onClick={() => handleRequestSort('amount')}
                                >
                                    Amount
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedEntries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((entry, index) => (
                            <TableRow hover key={index}>
                                <TableCell>{formatDate(entry.TransactionDate)}</TableCell>
                                <TableCell>{entry.TransactionType}</TableCell>
                                <TableCell>{entry.CustomerName}</TableCell>
                                <TableCell align="right" style={{ color: entry.Amount < 0 ? 'red' : 'green' }}>
                                    £{(entry.Amount !== undefined ? entry.Amount : 0).toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => handleClickOpen(entry)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={5} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={sortedEntries.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <DeleteConfirmationDialog
                open={open}
                handleClose={handleClose}
                handleConfirmDelete={handleConfirmDelete}
            />
        </Paper>
    );
};

export default LedgerTable;
