import React, { createContext, useState, useMemo, useEffect } from 'react';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

// Create a context for the theme
export const ThemeContext = createContext({
  theme: 'light',
  toggleTheme: () => { },
});

export const primaryColor = "#ecb24e"; // gold
export const contrastPrimaryColor = "#4e8aec" // lighter gold
export const secondaryColor = "#2a3a4a"; // dark blue
export const backgroundColor = "#28353e"; // dark blue
export const textColor = "#FFFFFF"; // white

export const ThemeProvider = ({ children }) => {
  // Initialize theme from localStorage if available or default to 'light'
  const [theme, setTheme] = useState(
    typeof window !== 'undefined' ? localStorage.getItem('theme') || 'light' : 'light'
  );

  // Define your colors
  const gradientStart = 'rgb(53,78,108)';
  const gradientEnd = 'rgb(28,30,29)';

  useEffect(() => {
    // Ensure window is defined before using localStorage
    if (typeof window !== 'undefined') {
      // Update localStorage when theme changes
      localStorage.setItem('theme', theme);
    }
  }, [theme]);

  // Toggle the current theme
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  // Create a theme instance with useMemo for performance optimization
  const themeInstance = useMemo(
    () => createTheme({
      palette: {
        mode: theme,
        ...(theme === 'dark' && {
          background: {
            default: backgroundColor,
            paper: backgroundColor,
          },
          text: {
            primary: textColor, // Primary text color
            secondary: '#b3b3b3', // Secondary text color
          },
          primary: {
            main: primaryColor,
          },
          secondary: {
            main: secondaryColor,
          },
          error: {
            main: '#cf6679', // Your dark theme error color
          },
          // Add other color overrides for dark mode here
        }),
      },
      components: {
        ...(theme === 'dark' && {
          // Override styles for the MUI Paper component
          MuiPaper: {
            styleOverrides: {
              root: {
                // If you want all your Paper components to have the gradient background
                background: `linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)`,
              },
            },
          },
          // You can add more component overrides if needed
        })
      },

    }),
    [theme]
  );

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <MUIThemeProvider theme={themeInstance}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
