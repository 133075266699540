import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Switch, Box, Typography, Avatar, IconButton, AppBar, Toolbar, useMediaQuery, SwipeableDrawer, Badge } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BookIcon from '@mui/icons-material/Book';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';

import { ThemeContext, primaryColor } from '../../context/themeContext'; // Manages the theme state
import { useScrollbarStyles } from '../../globalStyles/ScrollBar'; // Import scrollbar styles

import Logo from '../../assets/Logo.png';

const Sidebar = ({ onLogout, outOfStockProducts = [] }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const muiTheme = useTheme();
    const { theme, toggleTheme } = useContext(ThemeContext); // Destructure theme from your custom context
    const isDarkMode = theme === 'dark';

    const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const scrollbarStyles = useScrollbarStyles(); // Use the scrollbar styles

    // Access theme properties after checking if theme is defined
    const drawerZIndex = theme?.zIndex?.drawer;

    // This function will toggle the theme between light and dark
    const handleThemeChange = () => {
        toggleTheme();
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleListItemClick = (path) => {
        navigate(path);
        if (isMobile) {
            setMobileOpen(false);
        }
    };

    const handleNotificationsClick = () => {
        setDrawerOpen(true);
    };

    const handleNotificationsClose = () => {
        setDrawerOpen(false);
    };

    const navItems = [
        { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
        { text: 'Inventory', icon: <InventoryIcon />, path: '/inventory' },
        { text: 'Amazon Analytics', icon: <AnalyticsIcon />, path: '/analytics' },
        { text: 'Ledger', icon: <BookIcon />, path: '/ledger' },
    ];

    // Only show the AppBar if the screen is mobile size
    const appBar = isMobile ? (
        <AppBar position="fixed" sx={{ zIndex: drawerZIndex + 1 }}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    VitelCycles - Operations
                </Typography>
                <IconButton color="inherit" onClick={handleNotificationsClick}>
                    <Badge badgeContent={outOfStockProducts.length} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Toolbar>
        </AppBar>
    ) : null;

    const drawerContent = (
        <Box>
            <Box display="flex" alignItems="center" p={2} gap={2}>
                <Avatar variant="square" src={Logo} sx={{ bgcolor: 'grey.700', width: 40, height: 40 }} />
                <Typography variant="h6" color={primaryColor}>
                    VitelCycles™
                </Typography>
            </Box>
            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <ListItemButton
                        key={index}
                        selected={location.pathname === item.path}
                        onClick={() => handleListItemClick(item.path)}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItemButton>
                ))}
            </List>
            <Divider />
            <List>
                {!isMobile && (
                    <ListItemButton onClick={handleNotificationsClick}>
                        <ListItemIcon>
                            <Badge badgeContent={outOfStockProducts.length} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Notifications" />
                    </ListItemButton>
                )}
                <ListItemButton>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </ListItemButton>
                <ListItemButton onClick={onLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
            <Divider />
            <List>
                <ListItem>
                    <ListItemText primary={localStorage.getItem('name')} secondary={localStorage.getItem('email')} />
                </ListItem>
                <ListItem>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LightModeIcon />
                        <Switch
                            checked={isDarkMode}
                            onChange={handleThemeChange}
                            name="darkModeSwitch"
                            inputProps={{ 'aria-label': 'dark mode switch' }}
                        />
                        <DarkModeIcon />
                    </Box>
                </ListItem>
            </List>
        </Box>
    )

    return (
        <Box sx={{ display: 'flex' }}>
            {appBar}
            <Drawer
                variant={isMobile ? 'temporary' : 'permanent'}
                open={isMobile ? mobileOpen : true}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    width: 240,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': { width: 240, boxSizing: 'border-box' },
                }}
            >
                {drawerContent}
            </Drawer>
            <SwipeableDrawer
                anchor="right"
                open={drawerOpen}
                onClose={handleNotificationsClose}
                onOpen={handleNotificationsClick}
                sx={{ '& .MuiDrawer-paper': { width: '300px' } }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Out of Stock Products
                    </Typography>
                    {outOfStockProducts.length > 0 ? (
                        <Box sx={{ maxHeight: '400px', overflowY: 'auto', ...scrollbarStyles }}>
                            {outOfStockProducts.map((product, index) => (
                                <Box key={index} sx={{ mb: 1, borderBottom: '1px solid', borderColor: 'divider', pb: 1 }}>
                                    <Typography variant="body2">
                                        {product.ASIN} - {product.ProductName}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Typography variant="body2">All products are in stock</Typography>
                    )}
                </Box>
            </SwipeableDrawer>
        </Box>
    );
};

export default Sidebar;
