export function descendingComparator(a, b, orderBy) {
  // Handle strings case-insensitively
  if (typeof a[orderBy] === 'string') {
    return a[orderBy].toLowerCase().localeCompare(b[orderBy].toLowerCase());
  }

  // Handle dates
  if (typeof a[orderBy] === 'date') {
    const dateA = new Date(a[orderBy]);
    const dateB = new Date(b[orderBy]);
    return dateB < dateA ? -1 : dateB > dateA ? 1 : 0;
  }

  // Default numeric and integer comparison
  return b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function calculatePercentageChange(newVal, oldVal) {
  // If both values are 0, there's no change
  if (newVal === 0 && oldVal === 0) {
    return 0;
  }

  // VAULTED TEMPORARILY
  // If the old value is 0 but the new value isn't, this represents an infinite increase,
  // which you might want to handle specifically, such as by returning a special value or string.
  // if (oldVal === 0) {
  //   return '∞'; // or return a large number like 999999 to indicate a significant increase
  // }

  // If the new value is 0 and the old value isn't, this represents a decrease to 0,
  // which could be interpreted as a -100% change.
  if (newVal === 0) {
    return -100;
  }

  // Standard calculation for other cases
  return ((newVal - oldVal) / oldVal * 100).toFixed(2); // Added toFixed(2) for rounding to two decimal places
};