import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { DateRange } from 'react-date-range';
import Dialog from '@mui/material/Dialog';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './CalendarPickerStyles.css';

const CalendarPicker = ({ open, onClose, dateRange, setDateRange }) => {
  const theme = useTheme();
  const [currentRange, setCurrentRange] = useState(dateRange[0]);
  const [singleDateSelect, setSingleDateSelect] = useState(false);

  useEffect(() => {
    setCurrentRange(dateRange[0]);
  }, [open, dateRange]);

  const handleSelect = (ranges) => {
    const newRange = { ...ranges.selection };
    setCurrentRange(newRange);

    if (singleDateSelect) {
      setDateRange([{ ...newRange, endDate: newRange.startDate }]);
      onClose();
    } else {
      if (newRange.startDate && newRange.endDate) {
        if (newRange.startDate.getTime() !== newRange.endDate.getTime()) {
          setDateRange([newRange]);
          onClose();
        }
      }
    }
  };

  const handleToggle = () => {
    setSingleDateSelect(!singleDateSelect);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <FormControlLabel
        control={
          <Switch
            checked={singleDateSelect}
            onChange={handleToggle}
            color="primary"
          />
        }
        label="Single Date Select"
        sx={{ margin: '10px' }}
      />
      <DateRange
        className={theme.palette.mode === 'dark' ? 'dark-mode' : 'light-mode'}
        editableDateInputs={true}
        onChange={handleSelect}
        moveRangeOnFirstSelection={false}
        ranges={[currentRange]}
        rangeColors={[theme.palette.primary.main]}
        color={theme.palette.primary.main}
        showSelectionPreview={singleDateSelect ? false : true}
        showDateDisplay={singleDateSelect ? false : true}
      />
    </Dialog>
  );
};

export default CalendarPicker;