// Components
import React from 'react';
import { Box, Typography, Paper, Avatar, LinearProgress } from '@mui/material';
import TrendInfo from './TrendInfo';

const SummaryCard = ({ title, value, icon, trend, loading }) => (
  <Paper
    sx={{
      p: 1,
      pl: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      borderRadius: '16px',
    }}
    elevation={3}
  >
    <Typography color="textSecondary" gutterBottom>
      {title}
    </Typography>
    <Box display="flex" alignItems="center">
      <Avatar src={icon} />
      {loading && (
        <LinearProgress
          sx={{
            marginLeft: '10px',
            width: '100%',
          }}
        />
      )}
      <Typography variant="h5" component="p" sx={{ pl: 1, opacity: loading ? 0 : 1 }}>
        {value}
      </Typography>
    </Box>
    {/* Trend Info or placeholder */}
    {!loading ? <TrendInfo trend={trend} /> : <Box sx={{ height: '25px' }} />}
  </Paper>
);

export default SummaryCard;