import React, { useState, useContext } from 'react';
import { ThemeContext, primaryColor } from '../../context/themeContext';
import { Paper, Typography, Box, ToggleButton, ToggleButtonGroup, Grid, CircularProgress } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CustomTooltip from '../CustomToolTip';

const OrdersAnalysis = ({ products, loading }) => {
    const { theme } = useContext(ThemeContext);
    const [view, setView] = useState('amount'); // 'amount' or 'units'

    // Check if products is not empty
    const hasProducts = products && products.length > 0;

    // Calculate the total and find the best-selling product
    const totalAmount = hasProducts ? products.reduce((acc, product) => acc + product[view], 0) : 0;
    const bestProduct = hasProducts
        ? products.reduce((max, product) => max[view] > product[view] ? max : product, products[0])
        : { name: '', trendPercentage: '', trendValue: '', trend: '' };

    const handleViewChange = (_, nextView) => {
        if (nextView !== null) {
            setView(nextView);
        }
    };

    const CustomYAxisTick = ({ x, y, payload }) => {
        const displayName = payload.value.length > 10 ? `${payload.value.slice(0, 10)}...` : payload.value;
        return (
            <text x={x} y={y} dy={4} fill="#666" fontSize="12" textAnchor="end">
                {displayName}
            </text>
        );
    };

    return (
        <Paper
            sx={{
                borderRadius: '15px',
                boxShadow: '0px 4px 4px 1px rgba(0, 0, 0, 0.25)',
                p: 2,
                mt: 5,
                maxWidth: '100vw',  // Limit the overall width of the paper
                overflow: 'hidden',   // Ensure content doesn't overflow
            }}
            elevation={3}
        >
            <Typography variant="h6" gutterBottom>
                Orders Analysis ({view})
            </Typography>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height={300}>
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <ToggleButtonGroup
                            size="small"
                            value={view}
                            exclusive
                            onChange={handleViewChange}
                            aria-label="view"
                        >
                            <ToggleButton value="units" aria-label="units">
                                Units
                            </ToggleButton>
                            <ToggleButton value="amount" aria-label="amount">
                                Amount (£)
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Typography variant="h6" gutterBottom>
                            Total: {view === 'amount' ? `£${totalAmount.toLocaleString()}` : `${totalAmount} Units`}
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6} display="flex" justifyContent="flex-end">
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                sx={{
                                    display: 'inline-block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '80%', // Adjust this as necessary
                                }}
                            >
                                Best Product: {bestProduct.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ maxHeight: '300px', overflow: 'hidden' }}>  {/* Wrapper to limit chart height */}
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                layout="vertical"
                                data={products}
                                margin={{ top: 20, right: 30, bottom: 0, left: 20 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis type="category" dataKey="name" width={70} tick={<CustomYAxisTick />} />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />
                                <Bar dataKey={view} fill={theme === 'dark' ? primaryColor : '#82ca9d'} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </React.Fragment>
            )}
        </Paper>
    );
};

export default OrdersAnalysis;
