// Components
import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../../context/themeContext';
import { Box, Typography, Grid } from '@mui/material';
import SummaryCard from '../../components/SummaryCard';
import OrdersToday from '../../components/Graphs/OrdersToday';
import OrdersAnalysis from '../../components/Graphs/OrdersAnalysis';
import TotalNetProfit from '../../components/Graphs/TotalNetProfit';
import OutOfStockProducts from '../../components/Tables/OOSProducts';

// SVGs
import GraphIcon from '../../assets/GraphIcon.svg';
import InventoryIcon from '../../assets/InventoryIcon.svg';
import PendingIcon from '../../assets/PendingIcon.svg';
import BalanceIcon from '../../assets/BalanceIcon.svg';

// Redux
import { useDispatch, useSelector } from '../../redux/store';


import {
  fetchTimeDistributionData,
  fetchMostSoldData,
  fetchTrendData,
  fetchPayout,
  setExpectedPayoutToday,
  setExpectedPayoutYesterday,
  fetchBalance,
  fetchOosProducts,
  fetchProfitData,
} from "../../redux/slices/analytics";

// Functions
import {
  toLocalISOString,
  getStartOfYesterday,
  getEndOfYesterday,
  getMinutesAgo
} from '../../functions/DateUtils';

import { calculatePercentageChange } from '../../functions/GeneralFunctions';

// Styles
import useTooltipStyles from '../../globalStyles/ToolTip';

const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tooltipStyles = useTooltipStyles();
  const { theme } = useContext(ThemeContext);
  const { orderDistributions, ordersAnalysis, trendData, profitData, expectedPayoutToday, expectedPayoutYesterday, balance, oosProducts, isDistributionsLoading, isMostSoldLoading, isTrendDataLoading, isExpectedPayoutTodayLoading,
    isExpectedPayoutYesterdayLoading, isBalanceLoading, isOosLoading, isProfitDataLoading } = useSelector((state) => state.analytics);

  useEffect(() => {
    const fetchData = async () => {

      try {
        let payoutToday
        let payoutYesterday

        const startDate = toLocalISOString(getEndOfYesterday());
        const endDate = toLocalISOString(getMinutesAgo(3));


        const yesterdayStartDate = toLocalISOString(getStartOfYesterday());
        const yesterdayEndDate = toLocalISOString(getEndOfYesterday());

        // Fetch all data concurrently
        await Promise.all([
          // Card Data
          await dispatch(fetchTrendData(startDate, endDate, navigate)),
          await dispatch(fetchBalance(navigate)),

          // Graphs and Table Data
          await dispatch(fetchTimeDistributionData(startDate, endDate, navigate)),
          await dispatch(fetchMostSoldData(startDate, endDate, navigate)),
          await dispatch(fetchOosProducts(navigate)),

          // Expected Payout (Card Data)
          payoutToday = await dispatch(fetchPayout(startDate, endDate, navigate)),
          payoutYesterday = await dispatch(fetchPayout(yesterdayStartDate, yesterdayEndDate, navigate)),
          await dispatch(setExpectedPayoutToday(payoutToday)),
          await dispatch(setExpectedPayoutYesterday(payoutYesterday)),

          //Profits Graph
          await dispatch(fetchProfitData(startDate, endDate, payoutToday, navigate)),
        ]);


      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  const payoutChangePercentage = calculatePercentageChange(expectedPayoutToday, expectedPayoutYesterday);

  // Update summaryData to reflect the new state
  const summaryData = [
    { title: 'Sales Today', value: `£${trendData?.last_period?.total_sales?.toFixed(2)}`, icon: GraphIcon, trend: `${trendData?.sales_change_percentage?.toFixed(2)}% from yesterday`, loading: isTrendDataLoading },
    { title: "Today's Expected Payout", value: `£${expectedPayoutToday?.toFixed(2)}`, icon: PendingIcon, trend: `${payoutChangePercentage}% from yesterday`, loading: isExpectedPayoutTodayLoading && isExpectedPayoutYesterdayLoading },
    { title: 'Units Sold Today', value: `${trendData?.last_period?.total_units_sold}`, icon: InventoryIcon, trend: `${trendData?.units_sold_change_percentage?.toFixed(2)}% from yesterday`, loading: isTrendDataLoading },
    { title: 'Balance', value: `£${balance?.toFixed(2)}`, icon: BalanceIcon, trend: '', loading: isBalanceLoading },
  ];

  return (
    <Box
      sx={{
        color: theme === 'dark' ? 'common.white' : 'common.black',
        bgcolor: theme === 'dark' ? 'background.default' : 'background.paper',
        p: 3,
        minHeight: 'calc(100vh)',
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Welcome {localStorage.getItem('name')}!
      </Typography>

      {/* Card Icons */}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {summaryData.map((data, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <SummaryCard {...data} />
          </Grid>
        ))}
      </Grid>

      {/* Orders Today Section (Bar Chart) */}
      <OrdersToday orders={orderDistributions} toolTipStyles={tooltipStyles} loading={isDistributionsLoading} />

      <OutOfStockProducts products={oosProducts} loading={isOosLoading} />

      <OrdersAnalysis products={ordersAnalysis} toolTipStyles={tooltipStyles} loading={isMostSoldLoading} />

      <TotalNetProfit products={profitData} toolTipStyles={tooltipStyles} loading={isProfitDataLoading}  />

    </Box>
  );
};

export default DashboardPage;
