export const toLocalISOString = (date) => {
    const offset = date.getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = (new Date(date - offset)).toISOString().slice(0, -1);
    return localISOTime;
};

export const getTodayFormatted = () => {
    return new Date().toISOString().split('T')[0];
    // return '2023-12-31';
};

export const getYesterdayFormatted = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
    // return '2023-12-30';
};

export const getStartOfToday = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0); // Start of today
    return date;
};

export const getStartOfYesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    date.setHours(0, 0, 0, 0); // Start of yesterday
    return date;
    // return new Date('2023-12-30T00:00:00');
};

export const getEndOfYesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    date.setHours(23, 59, 59, 999); // End of yesterday
    return date;
    // return new Date('2023-12-30T23:59:59');
};

export const getMinutesAgo = (minutes) => {
    const date = new Date();
    // let date = new Date('2023-12-31T17:24:35');
    date.setMinutes(date.getMinutes() - minutes); // x minutes ago
    return date;
};

export const getLast7Days = () => {
    const date = new Date();
    date.setDate(date.getDate() - 6); // Subtract 6 days to get the date 6 days ago
    date.setHours(0, 0, 0, 0); // Start of day
    return date;
};

export const getLast30Days = () => {
    const date = new Date();
    date.setDate(date.getDate() - 29); // Subtract 29 days to get the date 29 days ago
    date.setHours(0, 0, 0, 0); // Start of day
    return date;
};

export const getLast12Months = () => {
    const date = new Date();
    const today = new Date();
    
    date.setMonth(date.getMonth() - 12); // Subtract 12 months to get the date 12 months ago
    date.setDate(today.getDate()); // Ensure the day of the month is the same as today
    date.setHours(0, 0, 0, 0); // Start of day
    
    return date;
};
