import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import CalendarPicker from '../Calendar/CalendarPicker';

const TransactionForm = ({ open, handleClose, onAddEntry, transaction, setTransaction }) => {
    const [isCalendarOpen, setCalendarOpen] = useState(false);

    const handleOpenCalendar = () => {
        setCalendarOpen(true);
    };

    const handleCloseCalendar = () => {
        setCalendarOpen(false);
    };

    const handleDateChange = (selectedRange) => {
        // Assuming the dateRange state in CalendarPicker is an array with one object
        const newDate = selectedRange[0].startDate.toISOString().split('T')[0];
        setTransaction(prev => ({ ...prev, date: newDate }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTransaction(prev => ({ ...prev, [name]: value }));
    };

    const handleAdd = () => {
        onAddEntry(transaction); // Call the passed in function with the new transaction
        handleClose(); // Close the dialog
        setTransaction({ type: '', customerName: '', date: new Date().toISOString().split('T')[0], amount: '' }); // Reset the form
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add a Transaction</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Type"
                    name="type"
                    fullWidth
                    value={transaction.type}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="dense"
                    label="Customer Name"
                    name="customerName"
                    fullWidth
                    value={transaction.customerName}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="dense"
                    label="Date"
                    name="date"
                    fullWidth
                    value={transaction.date}
                    onClick={handleOpenCalendar} // Open calendar picker when the TextField is clicked
                    InputProps={{ readOnly: true }} // Make the TextField read-only
                />
                <CalendarPicker
                    open={isCalendarOpen}
                    onClose={handleCloseCalendar}
                    dateRange={[{ startDate: new Date(transaction.date), endDate: new Date(transaction.date), key: 'selection' }]}
                    setDateRange={handleDateChange}
                    singleDateSelect={true}
                />
                <TextField
                    margin="dense"
                    label="Amount"
                    type="number"
                    name="amount"
                    fullWidth
                    value={transaction.amount}
                    onChange={handleInputChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TransactionForm;
