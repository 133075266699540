import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { ThemeContext } from '../../context/themeContext';

const ErrorPage = () => {
  const { theme } = useContext(ThemeContext);  // Get the current theme (dark or light)
  const navigate = useNavigate();
  const location = useLocation();
  const errorMessage = location.state?.errorMessage || 'An unexpected error has occurred.';

  const handleBackToDashboard = () => {
    navigate('/dashboard');  // Navigate back to the dashboard or any other safe route
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full viewport height to prevent scrolling
        width: '100vw',  // Full viewport width
        bgcolor: theme === 'dark' ? 'background.default' : 'background.paper',
        color: theme === 'dark' ? 'common.white' : 'common.black',
        textAlign: 'center', // Center text horizontally
        // p: 2, // Add padding to prevent content from touching screen edges
        overflow: 'hidden', // Prevent scrolling
      }}
    >
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{
          fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem' }, // Responsive typography
        }}
      >
        Oops! Something went wrong
      </Typography>
      <Typography 
        variant="body1" 
        gutterBottom 
        sx={{
          fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' }, // Responsive for different screen sizes
        }}
      >
        {errorMessage}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackToDashboard}
        sx={{
          mt: 4,
          px: 4,  // Padding inside the button for better click area
          py: 1.5,
          fontSize: { xs: '0.8rem', sm: '1rem' }, // Adjust button font size for mobile screens
        }}
      >
        Back to Dashboard
      </Button>
    </Box>
  );
};

export default ErrorPage;
