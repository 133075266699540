import React, { useEffect, useContext, useState } from 'react';
import { ThemeContext } from '../../context/themeContext';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ProductProfileDialog from '../../components/Modals/ProductProfile';
import InventoryTable from '../../components/Tables/InventoryTable';

import { useDispatch, useSelector } from '../../redux/store';
import {
  fetchAmznProductsData,
  fetchInHouseProductsData,
  addInHouseProduct,
  updateInHouseProduct,
  updateAmznProduct
} from "../../redux/slices/inventory";
import { toLocalISOString, getMinutesAgo, getLast30Days } from '../../functions/DateUtils';

const InventoryPage = () => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();

  const { amznProducts, inHouseProducts, isAMZNProductsLoading, isInHouseProductsLoading } = useSelector((state) => state.inventory);

  const [isAddingNew, setIsAddingNew] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productType, setProductType] = useState('');
  const [isProfileOpen, setProfileOpen] = useState(false);

  // Define columns for Amazon and In-House products
  const amazonColumns = [
    { id: 'ASIN', label: 'ASIN' },
    { id: 'ProductName', label: 'Name' },
    { id: 'TotalQuantity', label: 'Total Quantity' },
    { id: 'CostOfGoods', label: 'Cost of Goods' },
    { id: 'AmazonPrice', label: 'Price' },
    { id: 'StockValue', label: 'Stock Value' },
    { id: 'PredictedInventoryDays', label: 'Predicted Inv Days' },
  ];

  const inHouseColumns = [
    { id: 'ProductID', label: 'Product ID' },
    { id: 'ProductName', label: 'Name' },
    { id: 'InventoryCount', label: 'Inventory Count' },
    { id: 'CostOfProduct', label: 'Cost of Product' },
    { id: 'StockValue', label: 'Stock Value' },
    { id: 'CostOfGoods', label: 'Cost of Goods' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all data concurrently
        const formattedStartDate = toLocalISOString(getLast30Days());
        const formattedEndDate = toLocalISOString(getMinutesAgo(3));
        dispatch(fetchAmznProductsData(formattedStartDate, formattedEndDate));
        dispatch(fetchInHouseProductsData());

      } catch (error) {
        console.error('Error loading data:', error);
        enqueueSnackbar('Failed to load inventory data', { variant: 'error' });
      }
    };

    fetchData();
  }, [dispatch, enqueueSnackbar]);

  const handleAddInHouseProduct = () => {
    setSelectedProduct({
      ProductID: '',
      ProductName: '',
      InventoryCount: '',
      CostOfProduct: '',
      StockValue: '',
      CostOfGoods: '',
      Barcode: '',
    });
    setIsAddingNew(true);
    setProductType('inHouse');
    setProfileOpen(true);
  };

  const handleAmazonRowClick = (product) => {
    setSelectedProduct(product);
    setIsAddingNew(false);
    setProductType('amazon');
    setProfileOpen(true);
  };

  const handleInHouseRowClick = (product) => {
    setSelectedProduct(product);
    setIsAddingNew(false);
    setProductType('inHouse');
    setProfileOpen(true);
  };

  const handleClose = () => {
    setProfileOpen(false);
    setSelectedProduct(null);
  };

  const handleSave = (updatedProduct) => {
    if (isAddingNew) {
      if (productType === 'inHouse') {
        const formattedData = {
          ...(updatedProduct.ProductName && { name: updatedProduct.ProductName }),
          ...(updatedProduct.InventoryCount && { inventory_count: updatedProduct.InventoryCount }),
          ...(updatedProduct.CostOfProduct && { cost_of_product: updatedProduct.CostOfProduct }),
          ...(updatedProduct.CostOfGoods && { cost_of_goods: updatedProduct.CostOfGoods }),
          ...(updatedProduct.Barcode && { barcode: updatedProduct.Barcode }),
        };

        dispatch(addInHouseProduct(formattedData))
          .then(() => {
            enqueueSnackbar('In-House product added successfully', { variant: 'success' });
          })
          .catch((error) => {
            enqueueSnackbar('Failed to add In-House product', { variant: 'error' });
          });
      }
    } else {
      if (productType === 'amazon') {
        const formattedData = {
          asin: updatedProduct.ASIN,
          ...(updatedProduct.CostOfGoods && { cost_of_goods: updatedProduct.CostOfGoods }),
          ...(updatedProduct.Barcode && { barcode: updatedProduct.Barcode }),
          ...({ notifications_enabled: updatedProduct.NotificationsEnabled }),
        };

        dispatch(updateAmznProduct(formattedData))
          .then(() => {
            enqueueSnackbar('Amazon product updated successfully', { variant: 'success' });
          })
          .catch((error) => {
            enqueueSnackbar('Failed to update Amazon product', { variant: 'error' });
          });

      } else if (productType === 'inHouse') {
        const formattedData = {
          id: updatedProduct.ProductID,
          ...(updatedProduct.ProductName && { name: updatedProduct.ProductName }),
          ...(updatedProduct.InventoryCount && { inventory_count: updatedProduct.InventoryCount }),
          ...(updatedProduct.CostOfProduct && { cost_of_product: updatedProduct.CostOfProduct }),
          ...(updatedProduct.CostOfGoods && { cost_of_goods: updatedProduct.CostOfGoods }),
          ...(updatedProduct.Barcode && { barcode: updatedProduct.Barcode }),
        };

        dispatch(updateInHouseProduct(formattedData))
          .then(() => {
            enqueueSnackbar('In-House product updated successfully', { variant: 'success' });
          })
          .catch((error) => {
            enqueueSnackbar('Failed to update In-House product', { variant: 'error' });
          });
      }
    }
  };

  return (
    <Box
      sx={{
        color: theme === 'dark' ? 'common.white' : 'common.black',
        bgcolor: theme === 'dark' ? 'background.default' : 'background.paper',
        p: 3,
        minHeight: 'calc(100vh)',
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Inventory
      </Typography>

      {/* Amazon Products Section */}
      <InventoryTable
        productType="amazon"
        products={amznProducts}
        onRowClick={handleAmazonRowClick}
        columns={amazonColumns}
        loading={isAMZNProductsLoading}
      />

      {/* In-House Products Section */}
      <InventoryTable
        productType="inHouse"
        products={inHouseProducts}
        onRowClick={handleInHouseRowClick}
        onAddProduct={handleAddInHouseProduct}
        columns={inHouseColumns}
        loading={isInHouseProductsLoading}
      />

      {/* Product Profile Pop Up */}
      {selectedProduct && (
        <ProductProfileDialog
          product={selectedProduct}
          productType={productType}
          open={isProfileOpen}
          onClose={handleClose}
          onSave={(updatedProduct) => handleSave(updatedProduct, isAddingNew)}
          isAddingNew={productType === 'inHouse' && !selectedProduct.ProductID}
        />
      )}

    </Box>
  );
};

export default InventoryPage;
