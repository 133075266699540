import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';

// Redux
// ----------------------------------------------------------------------

let initialState = {
    allOrders: null,
    orderDistributions: null,
    repeatCustomers: null,
    ordersAnalysis: null,
    trendData: null,
    profitData: null,
    expectedPayoutToday: 0,
    expectedPayoutYesterday: 0,
    balance: 0,
    oosProducts: [],

    isDistributionsLoading: true,
    isOrdersLoading: true,
    isRepeatCustomersLoading: true,
    isMostSoldLoading: true,
    isTrendDataLoading: true,
    isExpectedPayoutTodayLoading: true,
    isExpectedPayoutYesterdayLoading: true,
    isBalanceLoading: true,
    isOosLoading: true,
    isProfitDataLoading: true,

    error: null,
};

export const slice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        // Setting Loading States
        setOrdersLoading(state, action) {
            state.isOrdersLoading = action.payload;
        },
        setDistrubitionLoading(state, action) {
            state.isDistributionsLoading = action.payload;
        },
        setRepeatCustomersLoading(state, action) {
            state.isRepeatCustomersLoading = action.payload;
        },
        setMostSoldLoading(state, action) {
            state.isMostSoldLoading = action.payload;
        },
        setTrendDataLoading(state, action) {
            state.isTrendDataLoading = action.payload;
        },
        setProfitDataLoading(state, action) {
            state.isProfitDataLoading = action.payload;
        },
        setExpectedPayoutTodayLoading(state, action) {
            state.isExpectedPayoutTodayLoading = action.payload;
        },
        setExpectedPayoutYesterdayLoading(state, action) {
            state.isExpectedPayoutYesterdayLoading = action.payload;
        },
        setBalanceLoading(state, action) {
            state.isBalanceLoading = action.payload;
        },
        setOosLoading(state, action) {
            state.isOosLoading = action.payload;
        },

        // Setters for data
        setAllOrdersData(state, action) {
            state.allOrders = action.payload;
        },
        setDistributionData(state, action) {
            state.orderDistributions = action.payload;
        },
        setRepeatCustomersData(state, action) {
            state.repeatCustomers = action.payload;
        },
        setMostSoldData(state, action) {
            state.ordersAnalysis = action.payload;
        },
        setTrendData(state, action) {
            state.trendData = action.payload;
        },
        setProfitData(state, action) {
            state.profitData = action.payload;
        },
        setExpectedPayoutToday(state, action) {
            state.expectedPayoutToday = action.payload;
            state.isExpectedPayoutTodayLoading = false;
        },
        setExpectedPayoutYesterday(state, action) {
            state.expectedPayoutYesterday = action.payload;
            state.isExpectedPayoutYesterdayLoading = false;

        },
        setBalance(state, action) {
            state.balance = action.payload;
        },
        setOosProducts(state, action) {
            state.oosProducts = action.payload;
        },


        // Handle errors
        hasError(state, action) {
            state.error = action.payload;
        },
    },

});

export const {
    hasError,
    setExpectedPayoutToday,
    setExpectedPayoutYesterday,
    setOrdersLoading,
    setRepeatCustomersLoading,
    setDistrubitionLoading,
    setMostSoldLoading,
    setExpectedPayoutTodayLoading,
    setExpectedPayoutYesterdayLoading,
    setBalanceLoading,
    setOosLoading,
    setProfitDataLoading,
} = slice.actions

export default slice.reducer;

export function fetchAllOrdersData(startDate, endDate, navigate) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(slice.actions.setOrdersLoading(true));

    return async () => {

        
        try {
            const response = await axios.get(`${API_URL}/orders/all?start_date=${startDate}&end_date=${endDate}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(slice.actions.hasError(null));
            dispatch(slice.actions.setAllOrdersData(response.data.data));
            dispatch(slice.actions.setOrdersLoading(false));

        } catch (error) {
            console.error('Error fetching orders data:', error);
            dispatch(slice.actions.setOrdersLoading(false));
            dispatch(slice.actions.hasError(error));

            navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message

            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function fetchRepeatCustomersData(startDate, endDate, navigate) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(slice.actions.setRepeatCustomersLoading(true));

    return async () => {
        
        try {
            const response = await axios.get(`${API_URL}/orders/repeat-postcode?start_date=${startDate}&end_date=${endDate}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(slice.actions.hasError(null));
            dispatch(slice.actions.setRepeatCustomersData(response.data.data));
            dispatch(slice.actions.setRepeatCustomersLoading(false));

        } catch (error) {
            console.error('Error fetching orders data:', error);
            dispatch(slice.actions.setRepeatCustomersLoading(false));
            dispatch(slice.actions.hasError(error));

            navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message

            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function fetchTimeDistributionData(startDate, endDate, navigate) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(slice.actions.setDistrubitionLoading(true));

    return async () => {
        
        try {
            const response = await axios.get(`${API_URL}/orders/time-distribution?start_date=${startDate}&end_date=${endDate}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(slice.actions.hasError(null));
            dispatch(slice.actions.setDistributionData(response.data.data));
            dispatch(slice.actions.setDistrubitionLoading(false));

        } catch (error) {
            console.error('Error fetching orders data:', error);
            dispatch(slice.actions.setDistrubitionLoading(false));
            dispatch(slice.actions.hasError(error));

            navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message

            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function fetchMostSoldData(startDate, endDate, navigate) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(slice.actions.setMostSoldLoading(true));

    return async () => {
        
        try {
            const response = await axios.get(`${API_URL}/orders/most-sold?start_date=${startDate}&end_date=${endDate}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(slice.actions.hasError(null));
            dispatch(slice.actions.setMostSoldData(response.data.data));
            dispatch(slice.actions.setMostSoldLoading(false));

        } catch (error) {
            console.error('Error fetching orders data:', error);
            dispatch(slice.actions.setMostSoldLoading(false));
            dispatch(slice.actions.hasError(error));

            navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message

            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function fetchTrendData(startDate, endDate, navigate) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');
    dispatch(slice.actions.setTrendDataLoading(true));
    dispatch(slice.actions.setDistrubitionLoading(true));
    dispatch(slice.actions.setExpectedPayoutYesterdayLoading(true));
    dispatch(slice.actions.setExpectedPayoutTodayLoading(true));
    dispatch(slice.actions.setOosLoading(true));
    dispatch(slice.actions.setMostSoldLoading(true));
    dispatch(slice.actions.setBalanceLoading(true));
    dispatch(slice.actions.setProfitData(true));

    const requestBody = {
        start_date: startDate,
        end_date: endDate,
    };

    return async () => {
        
        try {
            const response = await axios.post(`${API_URL}/orders/trend`, requestBody, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(slice.actions.setTrendDataLoading(false));
            dispatch(slice.actions.setTrendData(response.data.data));
            dispatch(slice.actions.hasError(null));

        } catch (error) {
            console.error('Logout failed! Error:', error.message);
            dispatch(slice.actions.setTrendDataLoading(false));
            dispatch(slice.actions.hasError(error));
            navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message
            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function fetchProfitData(startDate, endDate, payout, navigate) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(slice.actions.setProfitDataLoading(true));

    const requestBody = {
        start_date: startDate,
        end_date: endDate,
        payout: payout,
    };

    return async () => {
        
        try {
            const response = await axios.post(`${API_URL}/orders/profit`, requestBody, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(slice.actions.setProfitDataLoading(false));
            dispatch(slice.actions.setProfitData(response.data.data));
            dispatch(slice.actions.hasError(null));

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));

            navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message

            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function fetchPayout(startDate, endDate, navigate) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    const requestBody = {
        start_date: startDate,
        end_date: endDate,
    };

    return async () => {
        
        try {
            const response = await axios.post(`${API_URL}/amazon/financials/expected-payout`, requestBody, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(slice.actions.hasError(null));

            return response.data.data.expected_payout;

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));

            navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message

            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function fetchBalance(navigate) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');
    dispatch(slice.actions.setBalanceLoading(true));

    return async (dispatch) => {
        
        try {
            const response = await axios.get(`${API_URL}/amazon/financials/balance`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            dispatch(slice.actions.setBalanceLoading(false));
            dispatch(slice.actions.setBalance(response.data.data.balance));
            dispatch(slice.actions.hasError(null));

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.setBalanceLoading(false));
            dispatch(slice.actions.hasError(error));

            navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message

            return Promise.reject(error); // Reject the promise on error
        }
    };
}

export function fetchOosProducts(navigate) {
    const API_URL = process.env.REACT_APP_API_URL;

    return async (dispatch) => {

        dispatch(slice.actions.setOosLoading(true));
        try {
            const response = await axios.get(`${API_URL}/inventory/amazon/oos`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            dispatch(slice.actions.setOosProducts(response.data.data));
            dispatch(slice.actions.setOosLoading(false));
        } catch (error) {
            console.error('Error fetching OOS products:', error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setOosLoading(false));
            navigate('/error', { state: { errorMessage: error.message } }); // Navigate to error page with error message
        }
    };
}