import React, { useContext } from 'react';
import { ThemeContext, primaryColor } from '../../context/themeContext';
import { Typography, Paper, CircularProgress, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Styles
import useTooltipStyles from '../../globalStyles/ToolTip';

const OrdersToday = ({ orders, loading }) => {
    const { theme } = useContext(ThemeContext);
    const tooltipStyles = useTooltipStyles();

    return (
        <Paper
            sx={{
                borderRadius: '15px',
                boxShadow: '0px 4px 4px 1px rgba(0, 0, 0, 0.25)',
                p: 2, // padding inside the card
                mt: 5, // margin-top for spacing from other elements
                maxWidth: '100vw',  // Limit the overall width of the paper
                overflow: 'hidden',   // Ensure content doesn't overflow
            }}
        >
            <Typography variant="h6" gutterBottom>
                Order's Timeframe Distribution
            </Typography>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height={300}>
                    <CircularProgress />
                </Box>
            ) : (
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        data={orders}
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip contentStyle={tooltipStyles} />
                        <Legend />
                        <Bar dataKey="Orders" fill={theme === 'dark' ? primaryColor : '#82ca9d'} />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </Paper>
    );
};

export default OrdersToday;
