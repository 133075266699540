import React, { useState, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Box,
    CircularProgress,
    Typography,
    TableSortLabel,
    Button,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getComparator } from '../../functions/GeneralFunctions';
import { useScrollbarStyles } from '../../globalStyles/ScrollBar';

const InventoryTable = ({ productType, products, onRowClick, onAddProduct, columns, loading }) => {
    const scrollbarStyles = useScrollbarStyles();
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');

    // Ensure products is always an array
    const safeProducts = products || [];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Handle search
    const filteredData = useMemo(() => {
        return safeProducts.filter(product => {
            return columns.some(column => {
                return String(product[column.id]).toLowerCase().includes(searchQuery.toLowerCase());
            });
        });
    }, [safeProducts, columns, searchQuery]);

    const sortedData = filteredData
        ? filteredData.slice().sort(getComparator(orderDirection, orderBy))
        : [];

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

    return (
        <Paper
            sx={{
                borderRadius: '15px',
                boxShadow: '0px 4px 4px 1px rgba(0, 0, 0, 0.25)',
                p: 2, // padding inside the card
                mt: 5, // margin-top for spacing from other elements
                maxWidth: '100vw',  // Limit the overall width of the paper
                overflow: 'hidden',   // Ensure content doesn't overflow
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">
                    {productType === 'amazon' ? 'List of Amazon Products' : 'List of In-House Products'}
                </Typography>
                {productType === 'inHouse' && (
                    <Button onClick={onAddProduct} startIcon={<AddIcon />}>
                        Add In-House Product
                    </Button>
                )}
            </Box>
            <Box mb={2}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    disabled={loading}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <TableContainer sx={{ maxHeight: 440, boxShadow: 'none', border: 'none', overflowX: 'auto', ...scrollbarStyles }}>
                        <Table stickyHeader aria-label="sticky table" sx={{ border: 'none', minWidth: 800 }}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? orderDirection : 'asc'}
                                                onClick={() => handleRequestSort(column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
                                    <TableRow hover key={index} onClick={() => onRowClick(product)}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id}>{product[column.id]}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={columns.length} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
    );
};

export default InventoryTable;
