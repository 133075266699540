import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';

// Initial state
const initialState = {
    transactions: [],
    isLoading: false,
    isAdding: false,
    isDeleting: false,
    error: null,
};

export const ledgerSlice = createSlice({
    name: "ledger",
    initialState,
    reducers: {
        // Setting Loading States
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setAdding(state, action) {
            state.isAdding = action.payload;
        },
        setDeleting(state, action) {
            state.isDeleting = action.payload;
        },
        // Set Transactions Data
        setTransactionsData(state, action) {
            state.transactions = action.payload;
        },
        // Handle errors
        hasError(state, action) {
            state.error = action.payload;
        },
    },
});

// Export actions
export const {
    setLoading,
    setAdding,
    setDeleting,
    setTransactionsData,
    hasError,
} = ledgerSlice.actions;

export default ledgerSlice.reducer;

// Asynchronous Thunk to fetch transactions
export function fetchTransactions() {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(ledgerSlice.actions.setLoading(true));

    return async () => {
        try {
            const response = await axios.get(`${API_URL}/ledger/transactions`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(ledgerSlice.actions.hasError(null));
            dispatch(ledgerSlice.actions.setTransactionsData(response.data.data));
            dispatch(ledgerSlice.actions.setLoading(false));

        } catch (error) {
            console.error('Error fetching transactions data:', error);
            dispatch(ledgerSlice.actions.setLoading(false));
            dispatch(ledgerSlice.actions.hasError(error));
            return Promise.reject(error); // Reject the promise on error
        }
    };
}

// Asynchronous Thunk to add a transaction
export function addTransactionToDb(transaction) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(ledgerSlice.actions.setAdding(true));

    return async () => {
        try {
            await axios.post(`${API_URL}/ledger/transactions`, transaction, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(ledgerSlice.actions.hasError(null));
            dispatch(ledgerSlice.actions.setAdding(false));
            dispatch(fetchTransactions());

        } catch (error) {
            console.error('Error adding transaction:', error);
            dispatch(ledgerSlice.actions.setAdding(false));
            dispatch(ledgerSlice.actions.hasError(error));
            return Promise.reject(error); // Reject the promise on error
        }
    };
}

// Asynchronous Thunk to delete a transaction
export function deleteTransactionFromDb(transactionId) {
    const API_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');

    dispatch(ledgerSlice.actions.setDeleting(true));

    return async () => {
        try {
            await axios.delete(`${API_URL}/ledger/transactions/${transactionId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            dispatch(ledgerSlice.actions.hasError(null));
            dispatch(ledgerSlice.actions.setDeleting(false));
            dispatch(fetchTransactions());

        } catch (error) {
            console.error('Error deleting transaction:', error);
            dispatch(ledgerSlice.actions.setDeleting(false));
            dispatch(ledgerSlice.actions.hasError(error));
            return Promise.reject(error); // Reject the promise on error
        }
    };
}
